import { api } from "./api";
const metabolitesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        tracerMetabolites: builder.query({
            query: () => "/metabolites/tracers",
        }),
        gaugeMetabolites: builder.query({
            query: (tracer) => ({
                url: `/metabolites/gauges?tracer=${tracer}`,
            }),
        }),
        counts: builder.query({
            query: (args) => ({
                url: "/query/counts/",
                method: "POST",
                body: args,
            }),
        }),
        pathways: builder.query({
            query: (args) => ({
                url: "/query/pathways/",
                method: "POST",
                body: args,
            }),
        }),
        metabolites: builder.query({
            query: (args) => ({
                url: "/query/metabolites/",
                method: "POST",
                body: args,
            }),
        }),
        reactions: builder.query({
            query: (args) => ({
                url: "/query/reactions/",
                method: "POST",
                body: args,
            }),
        }),
        pathway: builder.query({
            query: (args) => `/metabolites/path/${args}`,
        }),
        allMetabolites: builder.query({
            query: (args) => ({
                url: `/metabolites?query=${args.query}&offset=${args.offset}&limit=${args.limit}`,
                method: "GET",
            }),
        }),
        newMetabolite: builder.mutation({
            query: (args) => ({
                url: "/metabolites/new",
                method: "POST",
                body: args,
            }),
        }),
        metabolite: builder.mutation({
            query: (args) => ({
                url: "/metabolites/mutate",
                method: "POST",
                body: args,
            }),
        }),
    }),
});
export const { useTracerMetabolitesQuery, useGaugeMetabolitesQuery, useCountsQuery, usePathwaysQuery, useMetabolitesQuery, useReactionsQuery, usePathwayQuery, useAllMetabolitesQuery, useNewMetaboliteMutation, useMetaboliteMutation, } = metabolitesApi;
