import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { useTokenQuery } from "./api";
import { auth } from "./reducers";
import { useAppDispatch, useAppSelector } from "./store";
export const LoginRedirect = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const [authToken] = useAppSelector((state) => [state.auth.token]);
    const code = searchParams.get("code");
    const { data } = useTokenQuery({
        code: code,
        redirect: `${window.location.origin}/login_redirect`,
    });
    useEffect(() => {
        if (!data)
            return;
        window.localStorage.setItem("token", JSON.stringify(data.access_token));
        window.localStorage.setItem("user", JSON.stringify(data.user));
        dispatch(auth.actions.setAuth(data));
    }, [dispatch, data]);
    if (authToken) {
        return _jsx(Navigate, { to: "/" });
    }
    else {
        return _jsx(_Fragment, {});
    }
};
export const AuthButtons = () => {
    const [user] = useAppSelector((state) => [state.auth.user]);
    if (user) {
        return (_jsxs("div", { className: "vib-auth", children: [user.role === "admin" ? (_jsx(Link, { to: `admin`, className: "vib-auth-button vib-admin", children: "Access Admin" })) : (_jsx(_Fragment, {})), _jsxs("a", { className: "vib-auth-button vib-user-info", children: [user.first_name, " ", user.last_name] })] }));
    }
    else {
        return (_jsx("div", { className: "vib-auth", children: _jsx("a", { className: "vib-auth-button", href: `${SERVICE_URL}/auth/login?redirect=${window.location.origin}/login_redirect`, children: "Login" }) }));
    }
};
