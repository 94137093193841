import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
const baseQuery = retry(fetchBaseQuery({
    baseUrl: SERVICE_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set("authentication", `Bearer ${token}`);
        }
        return headers;
    },
}), { maxRetries: 6 });
export const api = createApi({
    baseQuery,
    endpoints: (builder) => ({
        maintenance: builder.query({
            query: () => ({ url: "/state/maintenance" }),
        }),
    }),
});
export const { useMaintenanceQuery } = api;
