import { api } from "./api";
const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        token: builder.query({
            query: (req) => ({
                url: "/auth/token/",
                method: "POST",
                body: req,
            }),
        }),
    }),
});
export const { useTokenQuery } = authApi;
