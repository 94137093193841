import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MetaboliteList } from "./MetaboliteList";
const TabPanel = (props) => {
    return (_jsx("div", { role: "tabpanel", hidden: props.value !== props.index, children: props.value === props.index && _jsx(Box, { sx: { p: 3 }, children: props.children }) }));
};
export const Admin = () => {
    const [which, setWhich] = useState(0);
    const handleChange = (_ev, newValue) => {
        setWhich(newValue);
    };
    return (_jsxs(Box, { sx: { width: "100%", bgcolor: "background.paper" }, children: [_jsx("h2", { children: "Administration" }), _jsxs(Tabs, { value: which, onChange: handleChange, centered: true, children: [_jsx(Tab, { label: "Metabolites" }), _jsx(Tab, { label: "Users" })] }), _jsx(TabPanel, { value: which, index: 0, children: _jsx(MetaboliteList, {}) }), _jsx(TabPanel, { value: which, index: 1, children: "Users List" })] }));
};
