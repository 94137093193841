import { createSlice } from "@reduxjs/toolkit";
const initialMetaboliteState = {
    tracer: undefined,
    tracer_labeling: [],
    gauge: undefined,
    label_count: undefined,
    pathway_count: undefined,
    pathway: undefined,
    reactions: undefined,
    reaction_index: undefined,
};
const initialAuthState = {
    token: window.localStorage.getItem("token"),
    user: JSON.parse(window.localStorage.getItem("user") ?? "null"),
};
export const metabolites = createSlice({
    name: "metabolites",
    initialState: initialMetaboliteState,
    reducers: {
        setTracer: (state, action) => {
            state.tracer = action.payload;
            state.tracer_labeling = [];
            state.gauge = undefined;
            state.label_count = undefined;
            state.pathway_count = undefined;
            state.pathway = undefined;
            state.reactions = undefined;
            state.reaction_index = undefined;
        },
        setGauge: (state, action) => {
            state.gauge = action.payload;
            state.label_count = undefined;
            state.pathway_count = undefined;
            state.pathway = undefined;
            state.reactions = undefined;
            state.reaction_index = undefined;
        },
        toggleLabel: (state, action) => {
            state.tracer_labeling = state.tracer_labeling.includes(action.payload)
                ? state.tracer_labeling.filter((x) => x !== action.payload)
                : [...state.tracer_labeling, action.payload];
            if (state.tracer?.mol.atoms[action.payload] !== undefined) {
                state.tracer.mol.atoms[action.payload].selected =
                    !state.tracer.mol.atoms[action.payload]?.selected;
            }
            state.label_count = undefined;
            state.pathway_count = undefined;
            state.pathway = undefined;
            state.reactions = undefined;
            state.reaction_index = undefined;
        },
        setLabelCount: (state, action) => {
            state.label_count = action.payload;
            state.pathway_count = undefined;
            state.pathway = undefined;
            state.reactions = undefined;
            state.reaction_index = undefined;
        },
        setPathwayCount: (state, action) => {
            state.pathway_count = action.payload;
            state.pathway = undefined;
            state.reactions = undefined;
            state.reaction_index = undefined;
        },
        setPathway: (state, action) => {
            state.pathway = action.payload;
            state.reactions = undefined;
            state.reaction_index = undefined;
        },
        setReactionPath: (state, action) => {
            state.reactions = action.payload.reactions;
            state.reaction_index = action.payload.index;
        },
    },
});
export const auth = createSlice({
    name: "auth",
    initialState: initialAuthState,
    reducers: {
        setAuth: (state, action) => {
            state.token = action.payload.access_token;
            state.user = action.payload.user;
        },
    },
});
export const selectTracer = (state) => state.metabolites.tracer;
export const selectGauge = (state) => state.metabolites.gauge;
export const selectTracerLabeling = (state) => state.metabolites.tracer_labeling;
export const selectLabelCount = (state) => state.metabolites.label_count;
