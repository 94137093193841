import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Typography from "@mui/material/Typography";
import { Molecule } from "@vibbioinfocore/react-2d-molecule";
import { useAppSelector } from "./store";
import PlaceholderImage from "./tracer_placeholder.png";
export const TracerPlaceholder = () => {
    const tracer = useAppSelector((state) => state.metabolites.tracer);
    const atomStyle = (element, selected) => {
        return {
            fill: selected ? "#1cbbba" : element === "C" ? "rgba(1,1,1,0)" : "white",
            stroke: selected
                ? "#1cbbba"
                : element === "C"
                    ? "rgba(1,1,1,0)"
                    : "white",
        };
    };
    const atomLabelStyle = (element, selected) => ({
        fill: selected ? "white" : element === "C" ? "none" : "black",
        fontWeight: selected ? "bold" : "normal",
    });
    if (tracer) {
        return (_jsxs(React.Fragment, { children: [_jsx("div", { style: { fontSize: "0.7px", fontFamily: "sans-serif" }, children: _jsx(Molecule, { molecule: tracer.mol, width: 200, height: 200, atomStyle: atomStyle, atomLabelStyle: atomLabelStyle }) }), _jsx(Typography, { variant: "body2", children: tracer.name })] }));
    }
    else {
        return (_jsx(React.Fragment, { children: _jsx("img", { alt: "Tracer metabolite placeholder", src: PlaceholderImage, width: "200", height: "200" }) }));
    }
};
