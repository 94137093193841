import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { Molecule } from "@vibbioinfocore/react-2d-molecule";
export const MoleculeBox = (props) => {
    return (_jsxs(ImageListItem, { className: props.className, style: props.style, onClick: props.onClick, children: [_jsx("div", { style: {
                    fontSize: "0.7px",
                    fontFamily: "sans-serif",
                }, children: _jsx(Molecule, { molecule: props.molecule, width: props.width, height: props.height }) }), _jsx(ImageListItemBar, { title: props.label, position: "below" })] }));
};
