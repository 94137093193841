import { jsx as _jsx } from "react/jsx-runtime";
import { Molecule } from "@vibbioinfocore/react-2d-molecule";
import Box from "@mui/material/Box";
import { useAppDispatch } from "./store";
import { metabolites } from "./reducers";
import "./custom.css";
export const AtomSelector = (props) => {
    const dispatch = useAppDispatch();
    const handleClick = (index) => {
        dispatch(metabolites.actions.toggleLabel(index));
    };
    const atomStyle = (element, selected) => {
        return {
            fill: selected ? "#1cbbba" : element === "C" ? "rgba(1,1,1,0)" : "white",
            stroke: selected
                ? "#1cbbba"
                : element === "C"
                    ? "rgba(1,1,1,0)"
                    : "white",
        };
    };
    const atomLabelStyle = (element, selected) => ({
        fill: selected ? "white" : element === "C" ? "none" : "black",
        fontWeight: selected ? "bold" : "normal",
    });
    return (_jsx("div", { children: _jsx(Box, { children: _jsx("div", { style: { fontSize: "0.7px", fontFamily: "sans-serif" }, children: _jsx(Molecule, { molecule: props.metabolite.mol, width: 450, height: 500, atomClicked: handleClick, atomStyle: atomStyle, atomLabelStyle: atomLabelStyle }) }) }) }));
};
