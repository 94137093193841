import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector } from "./store";
import { TracerSelector } from "./TracerSelector";
import { GaugeSelector } from "./GaugeSelector";
import { LabelCounts } from "./LabelCounts";
import { PathwayCounts } from "./PathwayCounts";
import { AtomSelector } from "./AtomSelector";
import { Pathways } from "./Pathways";
import { Reactions } from "./Reactions";
export const TracerFateFormStep = (props) => {
    const [tracer] = useAppSelector((state) => [state.metabolites.tracer]);
    switch (props.step) {
        case 0:
            return _jsx(TracerSelector, {});
        case 1:
            return _jsx(AtomSelector, { metabolite: tracer });
        case 2:
            return _jsx(GaugeSelector, {});
        case 3:
            return _jsx(LabelCounts, {});
        case 4:
            return _jsx(PathwayCounts, {});
        case 5:
            return _jsx(Pathways, {});
        case 6:
            return _jsx(Reactions, {});
        default:
            return _jsx("div", { children: `Unknown step: ${props.step}` });
    }
};
