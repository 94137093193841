import { jsx as _jsx } from "react/jsx-runtime";
import { isNil, isNotNil, join, range, unless } from "ramda";
import { useNodes, BaseEdge, StepEdge, Position, } from "reactflow";
const roundTo10 = (pos) => ({
    x: Math.round(pos.x / 10) * 10,
    y: Math.round(pos.y / 10) * 10,
});
const drawPath = (source, target, path) => `M ${source.x} ${source.y} ` +
    join("", path.map(({ x, y }) => `L ${x}, ${y}`)) +
    `L ${target.x} ${target.y}`;
const NODE_PADDING = 2;
const boundingBox = (node) => {
    const width = Math.max(node.width ?? 0, 1);
    const height = Math.max(node.height ?? 0, 1);
    const pos = {
        x: node.positionAbsolute?.x ?? 0,
        y: node.positionAbsolute?.y ?? 0,
    };
    const topLeft = {
        x: pos.x - NODE_PADDING,
        y: pos.y - NODE_PADDING,
    };
    const bottomLeft = {
        x: pos.x - NODE_PADDING,
        y: pos.y + height + NODE_PADDING,
    };
    const topRight = {
        x: pos.x + width + NODE_PADDING,
        y: pos.y - NODE_PADDING,
    };
    const bottomRight = {
        x: pos.x + width + NODE_PADDING,
        y: pos.y + height + NODE_PADDING,
    };
    return {
        id: node.id,
        width,
        height,
        topLeft,
        bottomLeft,
        topRight,
        bottomRight,
    };
};
const boundingBoxes = (nodes) => {
    const boxes = nodes.map(boundingBox);
    console.log(boxes);
    const [xMin, xMax, yMin, yMax] = boxes.reduce(([iXMin, iXMax, iYMin, iYMax], box) => [
        Math.min(iXMin, box.topLeft.x),
        Math.max(iXMax, box.bottomRight.x),
        Math.min(iYMin, box.topLeft.y),
        Math.max(iYMax, box.bottomRight.y),
    ], [
        Number.MAX_SAFE_INTEGER,
        Number.MIN_SAFE_INTEGER,
        Number.MAX_SAFE_INTEGER,
        Number.MIN_SAFE_INTEGER,
    ]);
    const width = xMax - xMin;
    const height = yMax - yMin;
    return {
        nodeBoxes: boxes,
        graphBox: {
            topLeft: { x: xMin, y: yMin },
            bottomLeft: { x: xMin, y: yMax },
            topRight: { x: xMax, y: yMin },
            bottomRight: { x: xMax, y: yMax },
            width,
            height,
            xMax,
            yMax,
            xMin,
            yMin,
        },
    };
};
const mkGridNode = (x, y) => ({
    x,
    y,
    f: 0,
    g: 0,
    h: 0,
    backtrack: null,
    opened: false,
    closed: false,
    walkable: false,
});
const graphToGridPoint = (grid, point) => ({
    x: Math.floor(point.x / grid.ratio) + Math.floor(grid.xMin / grid.ratio - 1),
    y: Math.floor(point.y / grid.ratio) + Math.floor(grid.yMin / grid.ratio - 1),
});
const gridToGraphPoint = (grid) => (point) => ({
    x: grid.ratio * point.x + grid.ratio - grid.xMin,
    y: grid.ratio * point.y + grid.ratio - grid.yMin,
});
const makeWalkable = (grid, x, y) => {
    if (x >= 0 && x < grid.cols && y >= 0 && y < grid.rows) {
        grid.data[y * grid.cols + x].walkable = true;
    }
};
const adjacentPoint = (point, position) => {
    switch (position) {
        case Position.Top:
            return { ...point, y: point.y - 1 };
        case Position.Bottom:
            return { ...point, y: point.y + 1 };
        case Position.Left:
            return { ...point, x: point.x - 1 };
        case Position.Right:
            return { ...point, x: point.x + 1 };
        default:
            return null;
    }
};
const mkGrid = (graphBox, nodeBoxes, source, target) => {
    const { xMin, yMin, width, height } = graphBox;
    const [rows, cols] = [Math.ceil(height / 10), Math.ceil(width / 10)];
    const grid = {
        data: range(0, rows * cols).map((i) => {
            return mkGridNode(i % cols, Math.floor(i / cols));
        }),
        rows,
        cols,
        ratio: 10,
        xMin,
        yMin,
    };
    for (const nodeBox of nodeBoxes) {
        const start = graphToGridPoint(grid, nodeBox.topLeft);
        const end = graphToGridPoint(grid, nodeBox.bottomRight);
        for (let x = start.x; x < end.x; x++) {
            for (let y = start.y; y < end.y; y++) {
                makeWalkable(grid, x, y);
            }
        }
    }
    console.log(width, height, roundTo10(source));
    const startPoint = adjacentPoint(graphToGridPoint(grid, roundTo10(source)), source.position);
    const endPoint = adjacentPoint(graphToGridPoint(grid, roundTo10(target)), target.position);
    if (startPoint === null || endPoint === null) {
        return null;
    }
    return {
        grid,
        startPoint,
        endPoint,
    };
};
const at = (grid) => ({ x, y }) => {
    if (x >= 0 && x < grid.cols && y >= 0 && y < grid.rows) {
        return grid.data[y * grid.cols + x] || null;
    }
    return null;
};
const isWalkable = (grid) => ({ x, y }) => x >= 0 &&
    x < grid.cols &&
    y >= 0 &&
    y < grid.rows &&
    grid.data[y * grid.cols + x].walkable;
const neighbours = (grid, position) => [
    { x: position.x - 1, y: position.y }, // ←
    { x: position.x - 1, y: position.y - 1 }, // ↖
    { x: position.x, y: position.y - 1 }, // ↑
    { x: position.x + 1, y: position.y - 1 }, // ↗
    { x: position.x + 1, y: position.y }, // →
    { x: position.x + 1, y: position.y + 1 }, // ↘
    { x: position.x, y: position.y + 1 }, // ↓
    { x: position.x - 1, y: position.y + 1 }, // ↙
]
    .map(at(grid))
    .filter(isNotNil)
    .filter(isWalkable(grid));
const heappop = (heap) => {
    const [_unused, i] = heap.reduce(([smallest, idx], item, ci) => item.f < smallest ? [item.f, ci] : [smallest, idx], [Number.MIN_SAFE_INTEGER, -1]);
    if (i > -1) {
        const x = heap.splice(i, 1);
        if (x.length === 1) {
            return x[0];
        }
    }
    return null;
};
const backtrack = (grid, end) => {
    let node = at(grid)(end);
    if (node === null) {
        return null;
    }
    const path = [{ x: node.x, y: node.y }];
    while (isNotNil(node.backtrack)) {
        node = node.backtrack;
        path.push({ x: node.x, y: node.y });
    }
    return path;
};
const astar = (grid, start, end) => {
    const startNode = at(grid)(start);
    if (startNode === null) {
        console.log(`startNode is NULL: ${JSON.stringify(start)}`);
        console.log(grid);
        return null;
    }
    startNode.opened = true;
    const open = [startNode];
    while (open.length > 0) {
        const node = heappop(open);
        if (node === null) {
            console.log("Top of the heap is NULL");
            return null;
        }
        node.closed = true;
        if (node.x === end.x && node.y === end.y) {
            console.log("A* finished properly");
            return backtrack(grid, end);
        }
        neighbours(grid, node)
            .filter((neighbour) => !neighbour.closed)
            .forEach((neighbour) => {
            const cost = node.g +
                (neighbour.x - node.x === 0 || neighbour.y - node.y === 0
                    ? 1
                    : Math.sqrt(2));
            if (!neighbour.opened || cost < neighbour.g) {
                neighbour.g = cost;
                neighbour.h =
                    neighbour.h ||
                        Math.sqrt((Math.abs(neighbour.x - end.x) ** 2,
                            Math.abs(neighbour.y - end.y) ** 2));
                neighbour.f = neighbour.g + neighbour.h;
                neighbour.backtrack = node;
                if (!neighbour.opened) {
                    neighbour.opened = true;
                    open.push(neighbour);
                }
            }
        });
    }
    console.log("A* finished with an error");
    return null;
};
const computeEdge = (sourcePosition, // "bottom"
targetPosition, // "top"
sourceX, // 612.5
sourceY, // 4118
targetX, //612.5
targetY, // 4272
nodes) => {
    const { nodeBoxes, graphBox } = boundingBoxes(nodes);
    const grid = mkGrid(graphBox, nodeBoxes, { x: sourceX, y: sourceY, position: sourcePosition }, { x: targetX, y: targetY, position: targetPosition });
    if (grid === null) {
        return null;
    }
    const path = unless(isNil, (p) => p.map(gridToGraphPoint(grid.grid)), astar(grid.grid, grid.startPoint, grid.endPoint));
    if (path === null) {
        return null;
    }
    return {
        path: drawPath(grid.startPoint, grid.endPoint, path),
        centre: path.at(Math.floor(path.length / 10)),
    };
};
export const Edge = (props) => {
    const nodes = useNodes();
    const { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, style, label, labelStyle, labelShowBg, labelBgStyle, labelBgPadding, labelBgBorderRadius, markerEnd, markerStart, interactionWidth, } = props;
    const edge = computeEdge(sourcePosition, targetPosition, sourceX, sourceY, targetX, targetY, nodes);
    if (edge === null) {
        console.error("edge is NULL!");
        return _jsx(StepEdge, { ...props });
    }
    return (_jsx(BaseEdge, { path: edge.path, labelX: edge.centre.x, labelY: edge.centre.y, label: label, labelStyle: labelStyle, labelShowBg: labelShowBg, labelBgStyle: labelBgStyle, labelBgPadding: labelBgPadding, labelBgBorderRadius: labelBgBorderRadius, style: style, markerStart: markerStart, markerEnd: markerEnd, interactionWidth: interactionWidth }));
};
