import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { DataGrid, } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { Molecule } from "@vibbioinfocore/react-2d-molecule";
import { useAllMetabolitesQuery, useMetaboliteMutation, } from "./api/metabolites";
import { NewMoleculeForm } from "./NewMoleculeForm";
const PAGE_SIZE = 25;
export const MetaboliteList = () => {
    const mapPageToNextCursor = useRef({});
    const [mutateMetabolite] = useMetaboliteMutation();
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: PAGE_SIZE,
    });
    const [searchTerm, setSearchTerm] = useState("");
    const handlePaginationModelChange = (model) => {
        if (model.page === 0 || mapPageToNextCursor.current[model.page - 1]) {
            setPaginationModel(model);
        }
    };
    const queryOptions = useMemo(() => ({
        offset: (mapPageToNextCursor.current[paginationModel.page - 1] ??
            0),
        limit: paginationModel.pageSize,
    }), [paginationModel]);
    const { data, isLoading } = useAllMetabolitesQuery({
        query: searchTerm,
        ...queryOptions,
    });
    useEffect(() => {
        if (!isLoading && data !== undefined) {
            mapPageToNextCursor.current[paginationModel.page] = data.nextCursor;
        }
    }, [paginationModel.page, isLoading, data]);
    const columns = [
        { field: "id", headerName: "Identifier", flex: 1 },
        { field: "name", headerName: "Name", flex: 1 },
        { field: "size", headerName: "# Atoms", type: "number" },
        { field: "tracer", headerName: "Tracer?", type: "boolean", editable: true },
        { field: "gauge", headerName: "Gauge?", type: "boolean", editable: true },
        {
            field: "image",
            width: 250,
            headerName: "Molecule",
            renderCell: (params) => (_jsx("div", { style: { fontSize: "0.7px", fontFamily: "sans-serif" }, children: _jsx(Molecule, { molecule: params.row.mol, width: 240, height: 100, labelTranslateX: 0.1 }) })),
        },
    ];
    return (_jsxs("div", { style: { width: "100%" }, children: [_jsx(NewMoleculeForm, {}), _jsx(TextField, { label: "Filter", variant: "standard", inputProps: {
                    value: searchTerm,
                    onChange: (e) => {
                        // @ts-expect-error What do you mean it doesn't exist!?
                        const changed = e.target.value;
                        setSearchTerm(changed);
                    },
                } }), _jsx(DataGrid, { autoHeight: true, rows: data?.metabolites ?? [], columns: columns, loading: isLoading, pagination: true, paginationMode: "server", paginationModel: paginationModel, pageSizeOptions: [PAGE_SIZE], rowCount: data?.total ?? 0, onPaginationModelChange: handlePaginationModelChange, getRowHeight: () => "auto", keepNonExistentRowsSelected: true, onCellEditStop: (params) => {
                    void mutateMetabolite({
                        id: params.id.toString(),
                        field: params.field,
                        value: params.value,
                    });
                } })] }));
};
