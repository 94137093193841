import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import * as R from "ramda";
import { DataGrid, } from "@mui/x-data-grid";
import { usePathwaysQuery } from "./api/metabolites";
import { useAppSelector, useAppDispatch } from "./store";
import { metabolites } from "./reducers";
export const PathwayCounts = () => {
    const [rowSelection, setRowSelection] = useState([]);
    const [tracer, labeling, gauge, label_count, pathway_count] = useAppSelector((state) => [
        state.metabolites.tracer,
        state.metabolites.tracer_labeling,
        state.metabolites.gauge,
        state.metabolites.label_count,
        state.metabolites.pathway_count,
    ]);
    const dispatch = useAppDispatch();
    const { data, isLoading } = usePathwaysQuery({
        tracer: tracer.id,
        gauge: gauge.id,
        labels: labeling,
        label_count: label_count,
    });
    useEffect(() => {
        if (data && pathway_count) {
            setRowSelection(R.filter(R.isNotNil, Object.keys(data ?? {}).map((pathways, i) => {
                const index = parseInt(pathways);
                if (index === pathway_count) {
                    return i;
                }
                else {
                    return undefined;
                }
            })));
        }
    }, [data, pathway_count]);
    const columns = [
        { field: "pathways", width: 200, headerName: "Number of pathways" },
        { field: "paths", width: 350, headerName: "Number of metabolic paths" },
    ];
    const rows = Object.keys(data ?? {}).map((pathways, i) => ({
        id: i,
        pathways: parseInt(pathways),
        paths: data?.[parseInt(pathways)],
    }));
    const handleSelection = (newModel) => {
        const index = newModel[0];
        if (index !== undefined && data !== undefined) {
            const pathwayCounts = Object.keys(data);
            const pathwayCount = pathwayCounts[index];
            if (pathwayCount !== undefined) {
                dispatch(metabolites.actions.setPathwayCount(parseInt(pathwayCount)));
            }
        }
    };
    return (_jsx("div", { style: { height: 400, width: "100%" }, children: _jsx(DataGrid, { rows: rows, columns: columns, loading: isLoading, onRowSelectionModelChange: handleSelection, rowSelectionModel: rowSelection }) }));
};
