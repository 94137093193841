import { jsx as _jsx } from "react/jsx-runtime";
import Alert from "@mui/material/Alert";
import { TracerFateForm } from "./TracerFateForm";
import { useMaintenanceQuery } from "./api";
export const Main = () => {
    const response = useMaintenanceQuery({});
    if (response.isLoading) {
        return null;
    }
    else {
        if (response?.data === "ON") {
            return (_jsx(Alert, { severity: "warning", children: "The application is in maintenance mode" }));
        }
        else {
            return _jsx(TracerFateForm, {});
        }
    }
};
