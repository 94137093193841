import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, Link, RouterProvider, Outlet, } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { About } from "./About";
import { Admin } from "./Admin";
import { Main } from "./Main";
import { ErrorPage } from "./error-page";
import { AuthButtons, LoginRedirect } from "./auth";
import "@vibbioinfocore/vib-css";
import Logo from "./ttfd_logo.png";
const AppHeader = () => {
    return (_jsxs("header", { className: "vib-header", children: [_jsxs("div", { className: "vib-bar-breadcrumb", children: [_jsxs("div", { className: "vib-breadcrumbs", children: [_jsx("a", { className: "vib-main-site", href: "https://vib.be", target: "_blank", rel: "noopener noreferrer", children: _jsx("img", { className: "vib-logo-small", src: "https://vibbits.github.io/vib-css/images/vib.svg", alt: "VIB Logo" }) }), _jsx(Link, { className: "breadcrumb", to: "/", children: "Theoretical Tracer Fate Detection" })] }), _jsx(AuthButtons, {})] }), _jsxs("nav", { className: "vib-nav", children: [_jsx("img", { className: "vib-app-logo", src: Logo, alt: "TTFD Logo" }), _jsx("input", { type: "checkbox", id: "vib-toggle-menu" }), _jsxs("label", { className: "vib-menu-toggle-button", htmlFor: "vib-toggle-menu", children: [_jsx("span", { className: "vib-menu-button-bar vib-menu-top" }), _jsx("span", { className: "vib-menu-button-bar vib-menu-middle" }), _jsx("span", { className: "vib-menu-button-bar vib-menu-bottom" })] }), _jsxs("ul", { className: "vib-main-menu", role: "menu", children: [_jsx("li", { children: _jsx(Link, { to: `about`, children: "About" }) }), _jsx("li", { children: _jsx(Link, { to: `faq`, children: "FAQ" }) }), _jsx("li", { children: _jsx(Link, { to: `contact`, children: "Contact" }) })] })] })] }));
};
const AppFooter = () => {
    return (_jsxs("footer", { className: "vib-main-footer", children: [_jsx("div", { className: "footer-l" }), _jsxs("div", { className: "footer-r", children: [_jsx("h3", { children: "About" }), _jsxs("div", { children: ["\u00A9", " ", _jsx("a", { href: "https://metabolomicscore-leuven.sites.vib.be/", children: "VIB Metabolomics Core Facility, Leuven" }), " ", "2024."] }), _jsx("div", { style: { marginTop: "2rem" }, children: "Theoretical Tracer Fate Detection." }), _jsx("div", { style: { fontSize: "0.7em" }, children: VERSION })] })] }));
};
const App = () => {
    React.useEffect(() => {
        if (!document.body.classList.contains("vib-body")) {
            document.body.classList.add("vib-body");
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(AppHeader, {}), _jsx("main", { className: "vib-main", children: _jsx(Outlet, {}) }), _jsx(AppFooter, {})] }));
};
const router = createBrowserRouter([
    {
        path: "/",
        element: _jsx(App, {}),
        errorElement: _jsx(ErrorPage, {}),
        children: [
            { path: "about", element: _jsx(About, {}) },
            { path: "admin", element: _jsx(Admin, {}) },
            { path: "/", element: _jsx(Main, {}) },
        ],
    },
    {
        path: "/login_redirect",
        element: _jsx(LoginRedirect, {}),
        errorElement: _jsx(ErrorPage, {}),
    },
]);
const root = document.querySelector("#ttfd");
if (root) {
    const app = createRoot(root);
    app.render(_jsx(React.StrictMode, { children: _jsx(Provider, { store: store, children: _jsx(RouterProvider, { router: router }) }) }));
}
else {
    console.error("Could not find root element");
}
