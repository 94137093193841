import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { api } from "./api";
import { auth, metabolites } from "./reducers";
export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        [metabolites.name]: metabolites.reducer,
        [auth.name]: auth.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    devTools: true,
});
export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;
