import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Handle, Position } from "reactflow";
import { Molecule } from "@vibbioinfocore/react-2d-molecule";
import "./MetaboliteNode.css";
export const MetaboliteNode = ({ data }) => {
    return (_jsxs(_Fragment, { children: [_jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [_jsx("div", { style: { fontSize: "0.7px", fontFamily: "sans-serif" }, children: _jsx(Molecule, { molecule: data.mol, width: 100, height: 100 }) }), data.label] }), _jsx(Handle, { type: "target", position: Position.Top, id: "primary" }), _jsx(Handle, { type: "target", position: Position.Right, id: "secondary1" }), _jsx(Handle, { type: "target", position: Position.Left, id: "secondary2" }), _jsx(Handle, { type: "source", position: Position.Bottom })] }));
};
export const TracerNode = ({ data }) => {
    return (_jsxs(_Fragment, { children: [_jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [_jsx("div", { style: { fontSize: "0.7px", fontFamily: "sans-serif" }, children: _jsx(Molecule, { molecule: data.mol, width: 100, height: 100 }) }), data.label] }), _jsx(Handle, { type: "source", position: Position.Bottom })] }));
};
export const GaugeNode = ({ data }) => {
    return (_jsxs(_Fragment, { children: [_jsxs("div", { style: { display: "flex", flexDirection: "column" }, children: [_jsx("div", { style: { fontSize: "0.7px", fontFamily: "sans-serif" }, children: _jsx(Molecule, { molecule: data.mol, width: 100, height: 100 }) }), data.label] }), _jsx(Handle, { type: "target", position: Position.Top })] }));
};
