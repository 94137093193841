import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import * as R from "ramda";
import ReactFlow, { MarkerType } from "reactflow";
import Dagre from "@dagrejs/dagre";
import ELK from "elkjs/lib/elk.bundled.js";
import { useAppSelector } from "./store";
import { usePathwayQuery } from "./api/metabolites";
import { MoleculeBox } from "./MoleculeBox";
import { MetaboliteNode, TracerNode, GaugeNode } from "./MetaboliteNode";
import { Edge as MyEdge } from "./Edge";
import "reactflow/dist/style.css";
const nodeTypes = {
    metabolite: MetaboliteNode,
    tracer: TracerNode,
    gauge: GaugeNode,
};
const edgeTypes = {
    myedge: MyEdge,
};
const SingleArrowHead = () => {
    return (_jsxs("svg", { width: "20px", height: "80px", viewBox: "0 0 20 80", children: [_jsx("defs", { children: _jsx("marker", { id: "arrowhead", viewBox: "0 0 10 10", markerWidth: "10", markerHeight: "7", refX: "0", refY: "2", orient: "auto-start-reverse", children: _jsx("polygon", { points: "0 0, 3 2, 0 4" }) }) }), _jsx("line", { x1: 10, y1: 0, x2: 10, y2: 70, stroke: "#000", strokeWidth: 3, markerEnd: "url(#arrowhead)" })] }));
};
const DoubleArrowHeads = () => {
    return (_jsxs("svg", { width: "100px", height: "150px", viewBox: "0 0 100 150", children: [_jsx("defs", { children: _jsx("marker", { id: "arrowhead", viewBox: "0 0 10 10", markerWidth: "10", markerHeight: "7", refX: "0", refY: "2", orient: "auto-start-reverse", children: _jsx("polygon", { points: "0 0, 3 2, 0 4" }) }) }), _jsxs("g", { children: [_jsx("line", { x1: 50, y1: 0, x2: 50, y2: 70, stroke: "#000", strokeWidth: 4 }), _jsx("line", { x1: "0", y1: "70", x2: "150", y2: "70", stroke: "#000", strokeWidth: "4" })] })] }));
};
const ReactionArrow = (props) => {
    return (_jsxs("div", { style: R.mergeLeft({
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
        }, R.defaultTo({}, props.style)), children: [props.heads === 2 ? _jsx(DoubleArrowHeads, {}) : _jsx(SingleArrowHead, {}), props.name] }));
};
const appendSiblingSize = (pathway) => {
    const sizes = R.append(0, R.drop(1, pathway.map((metabolite) => metabolite.length)));
    return R.zip(pathway, sizes);
};
export const Results2 = () => {
    const [reactions, pathway] = useAppSelector((state) => [
        state.metabolites.reactions,
        state.metabolites.pathway,
    ]);
    const { data } = usePathwayQuery(pathway);
    return (_jsx("div", { style: { display: "flex", flexDirection: "column" }, className: "results", children: appendSiblingSize(R.defaultTo([], data))
            .flatMap(([metabolite, childSize], i) => [
            _jsx("div", { style: { display: "flex", justifyContent: "center" }, children: metabolite.map((m, j) => (_jsx("span", { style: { margin: 10 }, children: _jsx("div", { style: {
                            color: m.input_for_next_step ? "blue" : "inherit",
                            fontSize: "0.7px",
                            fontFamily: "sans-serif",
                        }, children: _jsx(MoleculeBox, { molecule: m.mol, label: m.common_name ? m.common_name : m.name, width: 300, height: 250 }) }) }, `${i}-${j}`))) }, i * 2),
            _jsx(ReactionArrow, { name: (reactions ?? [])[i] ?? "", heads: childSize }, i * 2 + 1),
        ])
            .slice(0, -1) }));
};
function appendTuple(el, tuple) {
    return [...tuple, el];
}
export const Results1 = () => {
    const [reaction, pathway, labels] = useAppSelector((state) => [
        state.metabolites.reaction_index,
        state.metabolites.pathway,
        state.metabolites.tracer_labeling,
    ]);
    return (_jsx("div", { style: {
            display: "flex",
            backgroundColor: "white",
            justifyContent: "center",
        }, children: _jsx("img", { style: {
                maxWidth: "100%",
            }, src: `${SERVICE_URL}/results/${pathway}.svg?labeled_atoms=${R.join(",", labels)}&reaction=${reaction}`, alt: "Pathway result" }) }));
};
const arrangeEdges = (edges, nodes) => {
    const handles = ["primary", "secondary1", "secondary2"];
    if (nodes !== undefined && edges !== undefined) {
        for (const node of nodes) {
            let targetIndex = 0;
            for (const edge of edges) {
                if (edge.target === node.id) {
                    edge.targetHandle = handles[targetIndex];
                    targetIndex++;
                }
            }
        }
        return edges;
    }
    return [];
};
const mkEdges = (colours) => ([start, end, reaction]) => {
    const begin = start.find((m) => m.input_for_next_step);
    return end.map((m, i) => ({
        id: `${begin.name}-${m.name}`,
        source: begin.name,
        target: m.name,
        targetHandle: "primary",
        label: i === 0 ? reaction : "",
        type: "myedge",
        markerEnd: { type: MarkerType.ArrowClosed },
        style: {
            stroke: colours[reaction],
            strokeWidth: 3,
        },
    }));
};
const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
const layoutNodes = (nodes, edges, options) => {
    g.setGraph(options);
    edges.forEach((edge) => g.setEdge(edge.source, edge.target));
    nodes.forEach((node) => g.setNode(node.id, node.data.label));
    if (nodes.length > 0) {
        Dagre.layout(g);
        const { width, height } = g.graph();
        return [
            nodes.map((node) => {
                const { x, y } = g.node(node.id);
                return { ...node, position: { x, y } };
            }),
            width ?? 0,
            height ?? 0,
        ];
    }
    return [nodes, 0, 0];
};
export const Results3 = () => {
    const [reactions, pathway, tracer, gauge] = useAppSelector((state) => [
        state.metabolites.reactions,
        state.metabolites.pathway,
        state.metabolites.tracer.id,
        state.metabolites.gauge.id,
    ]);
    const { data } = usePathwayQuery(pathway);
    const edges = R.flatten(R.zipWith(appendTuple, reactions, R.aperture(2, R.defaultTo([], data))).flatMap(mkEdges(genColours(reactions))));
    console.log(edges);
    const [nodes, width, height] = layoutNodes(R.defaultTo([], data).flatMap((metabolites) => {
        return metabolites.map((metabolite) => ({
            id: metabolite.name,
            position: { x: 0, y: 0 },
            type: metabolite.name === tracer
                ? "tracer"
                : metabolite.name === gauge
                    ? "gauge"
                    : "metabolite",
            data: {
                label: metabolite.common_name ?? metabolite.name,
                mol: metabolite.mol,
            },
            width: 150,
            height: 150,
        }));
    }), edges, { rankdir: "TB", nodesep: 300, ranksep: 150, edgesep: 100 });
    return (_jsx("div", { className: "metabolitenodes", style: {
            width: `${width + 100}px`,
            height: `${height + 150}px`,
            backgroundColor: "white",
        }, children: _jsx(ReactFlow, { nodes: nodes, edges: arrangeEdges(edges, nodes), nodeTypes: nodeTypes, edgeTypes: edgeTypes, preventScrolling: false, panOnDrag: false, zoomOnScroll: false }) }));
};
const layoutNodesElk = (nodes, edges) => {
    const options = {
        "elk.algorithm": "layered",
        "elk.direction": "DOWN",
        "elk.layered.spacing.nodeNodeBetweenLayers": 100,
        "elk.spacing.nodeNode": 80,
    };
    const elk = new ELK();
    return elk.layout({
        id: "results",
        // @ts-expect-error Small incompatibility between react-flow nodes and ElkNode
        children: nodes,
        // @ts-expect-error Small incompatibility between react-flow nodes and ElkNode
        edges: edges,
    }, { layoutOptions: options });
};
const hueToRgb = (hue) => {
    const f = (n) => {
        const k = (n + hue / 60) % 6;
        return Math.round((1 - Math.max(0, Math.min(k, 4 - k, 1))) * 255);
    };
    return `rgb(${f(5)}, ${f(3)}, ${f(1)})`;
};
const genColours = (reactions) => {
    const gen = (n) => n > 360 ? false : [hueToRgb(n), n + 360 / reactions.length + 1];
    return R.zipObj(reactions, R.unfold(gen, 0));
};
const Results4 = () => {
    const [reactions, pathway, tracer, gauge] = useAppSelector((state) => [
        state.metabolites.reactions,
        state.metabolites.pathway,
        state.metabolites.tracer.id,
        state.metabolites.gauge.id,
    ]);
    const [graph, setGraph] = useState(null);
    const { data } = usePathwayQuery(pathway);
    useEffect(() => {
        const edges = R.flatten(R.zipWith(appendTuple, reactions, R.aperture(2, R.defaultTo([], data))).flatMap(mkEdges(genColours(reactions))));
        const nodes = R.defaultTo([], data).flatMap((metabolites) => {
            return metabolites.map((metabolite) => ({
                id: metabolite.name,
                position: { x: 0, y: 0 },
                type: metabolite.name === tracer
                    ? "tracer"
                    : metabolite.name === gauge
                        ? "gauge"
                        : "metabolite",
                data: {
                    label: metabolite.common_name ?? metabolite.name,
                    mol: metabolite.mol,
                },
                width: 150,
                height: 150,
            }));
        });
        layoutNodesElk(nodes, edges)
            .then((g) => {
            console.log(g);
            g.children?.forEach((n, i) => {
                nodes[i].position.x = n.x ?? 0;
                nodes[i].position.y = n.y ?? 0;
            });
            setGraph({ nodes, edges, width: g.width ?? 0, height: g.height ?? 0 });
        })
            .catch((error) => console.error(error));
    }, [data, reactions, gauge, tracer]);
    return (_jsx("div", { className: "metabolitenodes", style: {
            width: `${(graph?.width ?? 800) + 100}px`,
            height: `${(graph?.height ?? 1000) + 150}px`,
            backgroundColor: "white",
        }, children: _jsx(ReactFlow, { nodes: graph?.nodes, edges: arrangeEdges(graph?.edges, graph?.nodes), nodeTypes: nodeTypes, edgeTypes: edgeTypes, preventScrolling: false, panOnDrag: false, zoomOnScroll: false }) }));
};
const displayExperiment = (which) => {
    switch (which) {
        case 0:
            return _jsx(Results1, {});
        case 1:
            return _jsx(Results3, {});
        case 2:
            return _jsx(Results4, {});
        default:
            return _jsx(_Fragment, { children: "Not an experiment" });
    }
};
export const Results = () => {
    const [which, setWhich] = React.useState(0);
    return (_jsxs(_Fragment, { children: [_jsxs(ButtonGroup, { variant: "contained", "aria-label": "outlined primary button group", children: [_jsx(Button, { onClick: () => {
                            setWhich(0);
                        }, children: "Static SVG" }), _jsx(Button, { onClick: () => {
                            setWhich(1);
                        }, children: "Flow Chart" }), _jsx(Button, { onClick: () => {
                            setWhich(2);
                        }, children: "Elk.js Layout" })] }), displayExperiment(which)] }));
};
