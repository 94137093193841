import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Typography from "@mui/material/Typography";
import { Molecule } from "@vibbioinfocore/react-2d-molecule";
import { useAppSelector } from "./store";
import PlaceholderImage from "./gauge_placeholder.png";
export const GaugePlaceholder = () => {
    const gauge = useAppSelector((state) => state.metabolites.gauge);
    if (gauge) {
        return (_jsxs(React.Fragment, { children: [_jsx("div", { style: { fontSize: "0.7px", fontFamily: "sans-serif" }, children: _jsx(Molecule, { molecule: gauge.mol, width: 200, height: 200 }) }), _jsx(Typography, { variant: "body2", children: gauge.name })] }));
    }
    else {
        return (_jsx(React.Fragment, { children: _jsx("img", { alt: "Tracer metabolite placeholder", src: PlaceholderImage, width: "200", height: "200" }) }));
    }
};
