import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Results } from "./Results";
import { TracerFateFormStep } from "./TracerFateFormStep";
import { useAppSelector } from "./store";
import { GaugePlaceholder } from "./GaugePlaceholder";
import { TracerPlaceholder } from "./TracerPlaceholder";
const steps = [
    "Tracer metabolite",
    "Tracer labelled atoms",
    "Gauge metabolite",
    "Number of labels",
    "Number of pathways",
    "Metabolites",
    "Reactions",
];
export const TracerFateForm = () => {
    const state = useAppSelector((state) => [
        state.metabolites.tracer !== undefined,
        state.metabolites.tracer_labeling.length !== 0,
        state.metabolites.gauge !== undefined,
        state.metabolites.label_count !== undefined,
        state.metabolites.pathway_count !== undefined,
        state.metabolites.pathway !== undefined,
        state.metabolites.reaction_index !== undefined,
    ]);
    const [activeStep, setActiveStep] = React.useState(0);
    const nextAvailable = state.at(activeStep);
    const handleReset = () => {
        setActiveStep(0);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    return (_jsxs("div", { style: { width: "90%" }, children: [_jsxs("div", { style: {
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "2rem",
                }, children: [_jsx(Card, { variant: "outlined", children: _jsx(CardContent, { children: _jsx(TracerPlaceholder, {}) }) }), _jsxs("div", { children: [_jsx(ArrowForwardIosIcon, {}), _jsx(ArrowForwardIosIcon, {}), _jsx(ArrowForwardIosIcon, {}), _jsx(ArrowForwardIosIcon, {})] }), _jsx(Card, { variant: "outlined", children: _jsx(CardContent, { children: _jsx(GaugePlaceholder, {}) }) })] }), _jsxs(Box, { sx: { width: "100%", marginTop: "1rem" }, children: [_jsx(Stepper, { activeStep: activeStep, alternativeLabel: true, children: steps.map((label) => {
                            const stepProps = {};
                            return (_jsx(Step, { ...stepProps, children: _jsx(StepLabel, { children: label }) }, label));
                        }) }), activeStep === steps.length ? (_jsxs(_Fragment, { children: [_jsx(Results, {}), _jsxs(Box, { sx: { display: "flex", flexDirection: "row", pt: 2 }, children: [_jsx(Button, { onClick: handleBack, color: "inherit", sx: { mr: 1 }, children: "Back" }), _jsx(Box, { sx: { flex: "1 1 auto" } }), _jsx(Button, { onClick: handleReset, children: "Reset" })] })] })) : (_jsxs(_Fragment, { children: [_jsx(TracerFateFormStep, { step: activeStep }), _jsxs(Box, { sx: { display: "flex", flexDirection: "row", pt: 2 }, children: [_jsx(Button, { color: "inherit", disabled: activeStep === 0, onClick: handleBack, sx: { mr: 1 }, children: "Back" }), _jsx(Box, { sx: { flex: "1 1 auto" } }), _jsx(Button, { onClick: handleNext, disabled: !nextAvailable, children: activeStep === steps.length - 1 ? "Search" : "Next" })] })] }))] })] }));
};
