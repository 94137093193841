import { jsx as _jsx } from "react/jsx-runtime";
import ImageList from "@mui/material/ImageList";
import { MoleculeBox } from "./MoleculeBox";
import "./custom.css";
const MAX_HEIGHT = 120;
const MAX_WIDTH = 270;
export const MetaboliteSelector = (props) => {
    return (_jsx(ImageList, { sx: { width: "100%", height: 450 }, cols: 3, rowHeight: 164, children: props.data.map((item) => {
            const ratio = Math.min(MAX_HEIGHT / item.metabolite.mol.height, MAX_WIDTH / item.metabolite.mol.width);
            return (_jsx(MoleculeBox, { className: item.selected ? "selected-border" : "hover-border", molecule: item.metabolite.mol, label: item.metabolite.name, style: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }, onClick: () => {
                    if (!item.selected) {
                        props.onSelect(item.metabolite);
                    }
                }, width: item.metabolite.mol.width * ratio, height: item.metabolite.mol.height * ratio }, item.metabolite.id));
        }) }));
};
