import { jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch, useAppSelector } from "./store";
import { metabolites } from "./reducers";
import { useTracerMetabolitesQuery } from "./api/metabolites";
import { MetaboliteSelector } from "./MetaboliteSelector";
export const TracerSelector = () => {
    const dispatch = useAppDispatch();
    const tracer = useAppSelector((state) => state.metabolites.tracer);
    const { data, isLoading } = useTracerMetabolitesQuery();
    if (isLoading) {
        return _jsx("div", { children: "Loading..." });
    }
    return (_jsx(MetaboliteSelector, { data: data.map((metabolite) => ({
            selected: (tracer && tracer.id === metabolite.id) ?? false,
            metabolite,
        })), onSelect: (mid) => dispatch(metabolites.actions.setTracer(mid)) }));
};
