import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useNewMetaboliteMutation } from "./api/metabolites";
export const NewMoleculeForm = () => {
    const [name, setName] = useState("");
    const [common, setCommon] = useState("");
    const [human, setHuman] = useState(false);
    const [mol, setMol] = useState("");
    const [newMetabolite] = useNewMetaboliteMutation();
    const handleSubmit = (e) => {
        void newMetabolite({
            id: name,
            name: common,
            human: false,
            mol,
        });
        e.preventDefault();
    };
    const handleFileChange = (e) => {
        if (e.target.files) {
            e.target.files[0]
                ?.text()
                .then((text) => {
                setMol(text);
            })
                .catch((error) => console.error(error));
        }
    };
    return (_jsxs("form", { onSubmit: handleSubmit, children: [_jsxs("label", { style: { display: "block" }, children: ["Molecule Name:", _jsx("input", { type: "text", value: name, onChange: (e) => {
                            setName(e.target.value);
                        } })] }), _jsxs("label", { style: { display: "block" }, children: ["Common Name:", _jsx("input", { type: "text", value: common, onChange: (e) => {
                            setCommon(e.target.value);
                        } })] }), _jsxs("label", { style: { display: "block" }, children: ["Human?", _jsx("input", { type: "checkbox", checked: human, onChange: (e) => {
                            setHuman(e.target.checked);
                        } })] }), _jsxs("label", { style: { display: "block" }, children: ["Mol file:", _jsx("input", { type: "file", onChange: handleFileChange })] }), _jsx("input", { type: "submit", value: "Submit", style: { display: "block" } })] }));
};
