import { jsx as _jsx } from "react/jsx-runtime";
import { useAppSelector, useAppDispatch } from "./store";
import { metabolites } from "./reducers";
import { useGaugeMetabolitesQuery } from "./api/metabolites";
import { MetaboliteSelector } from "./MetaboliteSelector";
export const GaugeSelector = () => {
    const [tracer, gauge] = useAppSelector((state) => [
        state.metabolites.tracer,
        state.metabolites.gauge,
    ]);
    const dispatch = useAppDispatch();
    const { data, isLoading } = useGaugeMetabolitesQuery(tracer?.id ?? "");
    if (isLoading) {
        return _jsx("div", { children: "Loading..." });
    }
    return (_jsx(MetaboliteSelector, { data: (data ?? []).map((metabolite) => ({
            selected: (gauge && gauge.id === metabolite.id) ?? false,
            metabolite,
        })), onSelect: (mid) => dispatch(metabolites.actions.setGauge(mid)) }));
};
